import { TypeBlock } from "../../../types/blocks";
import './styles.scss';

const Image = ({ id, attributes, content, className, style }: TypeBlock) => {

    return (
        <figure className={className} style={style}>
            <img
                src={attributes.src}
                alt={attributes.name}
                style={{
                    height: attributes.imgHeight,
                    objectFit: attributes.objectFit
                }} />
        </figure>
    );
}

export default Image;