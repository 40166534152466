// App.js
import React, { useState, useEffect } from 'react';
import { OpenAI } from 'openai';
import { Right } from '../../components/Structure/Right';
import { Left } from '../../components/Structure/Left';



const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true })

export const Chat = () => {
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<any>([]);

    const sendMessage = async (e: any) => {
        e.preventDefault();
        const userMessage = { text: userInput, sender: 'user' };
        setMessages([...messages, userMessage]);
        try {
            const response: any = await openai.completions.create({
                model: "text-davinci-003",
                prompt: userInput,
                temperature: 0.7,
                max_tokens: 150,
                top_p: 1.0,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
            });

            console.log(response);

            const botMessage = { text: response.data.choices[0].text.trim(), sender: 'bot' };
            setMessages([...messages, userMessage, botMessage]);
        } catch (error) {
            console.error('Error calling OpenAI:', error);
        }
        setUserInput('');
    };

    return (
        <>
            <Left
                urlLogoTop={'/'}
            />
            <Right
                top={true}
                logoHeight={'34px'}
                borderRadius={'34px'}
                classStyle={'right_section'}
            >
                <div className="App">
                    <h2>Chat com GPT-3</h2>
                    <div className="chat-box">
                        {messages.map((message: any, index: any) => (
                            <div key={index} className={`message ${message.sender}`}>
                                {message.text}
                            </div>
                        ))}
                    </div>
                    <form onSubmit={sendMessage}>
                        <input
                            type="text"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            placeholder="Digite sua mensagem..."
                        />
                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </Right>
        </>

    );
}
