import React from "react";
import { TypeBlock } from "../../../types/blocks";

const Paragraph = ({ attributes, content, className, style }: TypeBlock) => {

  return React.createElement(
    "p",
    {
      className: className,
      style: style,
    },
    content
  );
};

export default Paragraph;
