import React, { useEffect, useRef } from "react";

import "./styles.scss";
import { TypeBlock } from "../../../types/blocks";

const Column = ({ id, name, attributes, className, children, style }: TypeBlock) => {
    const { tag } = attributes;


    return React.createElement(
        "div",
        {
            style: style,
            className: className,
            "data-id": id,
        },
        children
    );
};

export default Column;
