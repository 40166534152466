import React from "react";// Ajuste o caminho conforme necessário
import { TypeBlock } from "../../../types/blocks";

const Heading = ({ attributes, content, className, style }: TypeBlock) => {
  const { tag } = attributes;

  return React.createElement(
    tag,
    {
      className: className,
      style: style,
    },
    content
  );
};

export default Heading;
