import React, { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'
//hocs
import { useAuth } from "../../hoc/UseAuth";
//libs
import { QUERY_BRANDSLUG } from "../../lib/Queries";
//styles
import l from "./signin.module.scss";
//utils
import getContrastYIQ from "../../utils/contrastColor";

export const Signin: React.FC<{}> = () => {
    const [error, setError] = useState(""); // Estado para armazenar mensagens de erro
    const userName = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const brand = QUERY_BRANDSLUG();
    const settings = brand?.settings;
    const files = brand?.files;
    const background = files?.find((file: { type: string }) => file.type === "BACKGROUND")

    const contrastPrimary = settings?.colorPrimaryLogin && getContrastYIQ(settings?.colorPrimaryLogin);
    const contrastSecondary = settings?.colorSecondaryLogin && getContrastYIQ(settings?.colorSecondaryLogin);

    const auth = useAuth();
    async function login() {
        try {
            const res = await auth.authenticate(userName.current?.value as string, password.current?.value as string);
            if (res === "success") {
                // Redirecionar para a home
                navigate("/", { replace: true });
                console.log("Login realizado com sucesso");
            } else {
                setError("Usuário ou senha inválidos");
            }
        } catch (err) { }
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            login();
        }
    }

    console.log(settings, l)

    return (
        <section className={l.login}>
            <div className={l.acesso}>
                <div
                    style={{
                        background: settings?.colorPrimaryLogin
                    }}
                    className={l.brands}>
                    <div className={l.brand}>
                        {brand &&
                            <figure>
                                <img
                                    src={settings?.logoLogin?.file?.url}
                                    alt={brand?.logoHightlight?.file.name} />
                            </figure>
                        }
                    </div>
                    <div className={l.brand_universe}>
                        <div className={l.name}>
                            <h1
                                style={{
                                    color: contrastPrimary
                                }}
                            >Brand Universe</h1>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        background: settings?.colorSecondaryLogin
                    }}
                    className={l.form_content}>
                    <form>
                        <div className={l.imagemsvg}></div>
                        <div className={l.field}>
                            <div className={l.name_input}>
                                <label
                                    style={{
                                        color: contrastSecondary
                                    }}
                                    htmlFor='userName'>
                                    User / E-mail
                                </label>
                            </div>
                            <input
                                style={
                                    {
                                        borderColor: contrastSecondary,
                                        color: contrastSecondary
                                    }
                                }
                                type='text'
                                className={`${l.userName} ${!error ? "" : l.error}`}
                                id='userName'
                                name='userName'
                                ref={userName}

                            />
                        </div>
                        <div className={l.field}>
                            <div className={l.name_input}>
                                <label
                                    style={{
                                        color: contrastSecondary
                                    }}
                                    htmlFor='password'>Senha</label>
                            </div>
                            <input
                                style={
                                    {
                                        borderColor: contrastSecondary,
                                        color: contrastSecondary
                                    }
                                }
                                type='password'
                                className={`${l.password} ${!error ? "" : l.error}`}
                                id='password'
                                name='password'
                                ref={password}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                        <div className={l.buttons} onClick={() => login()}>
                            <div
                                style={{

                                    background: contrastSecondary,
                                    border: `2px solid ${contrastSecondary}`,
                                    color: contrastSecondary && getContrastYIQ(contrastSecondary)
                                }}
                                className={l.button}>
                                Entrar
                            </div>
                            <div className={`${l.field} ${l.error}`}>
                                <p className={l.error_mensage}>{error}</p>
                            </div>
                            <div className={l.reset}>
                                {/* <a className={l.simple_button' onClick={forgotPassword}>
                  Esqueceu sua senha?
                </a> */}
                            </div>
                        </div>
                    </form>
                    <p
                        style={{
                            color: contrastSecondary
                        }}
                        className={l.footer_login}>
                        © Enredo 2023 - Brand Innovation
                    </p>
                </div>
            </div >
            <div className={l.image}>
                <figure>
                    {files &&
                        <img
                            src={background?.file?.url}
                            alt={background?.file?.name} />
                    }
                </figure>
            </div>
        </section >
    );
}