import React from "react";
import { TypeBlock } from "../../../types/blocks";

const Columns = (
    { attributes, content, name, className, children, allowTypes, style }:
        TypeBlock
) => {

    return React.createElement(
        "div",
        {
            className: className,
            style: style,
            allowTypes
        },
        children
    );
};


export default Columns;