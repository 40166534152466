import { Link } from "react-router-dom";
//components
import { Left } from "../../components/Structure/Left"
import { Right } from "../../components/Structure/Right"
//utils
import { ICON_Downloads, SBrand } from "../../utils/svgs"
//styles
import d from "./download.module.scss";
//query
import { QUERY_BRANDSLUG } from "../../lib/Queries";

export const Downloads = () => {
    const settings = QUERY_BRANDSLUG()?.settings;
    const sections = QUERY_BRANDSLUG()?.sections;

    const downloads: any = {}
    sections?.forEach((section: any) => {
        downloads[section.name] = [...section.downloads]
        section.subsections.forEach((subsection: any) => {
            downloads[section.name].push(...subsection.downloads)
        })
    })

    return (
        <>
            <Left
                urlLogoTop={'/'}
                settings={settings}
                logoTop={<SBrand color="#000" />}
                logoHeight={'34px'}
                title={'Downloads'}
                inside={'<p>Reunimos nesta página o acesso ao download de todos os arquivos.</p><p>Utilize sempre os arquivos originais disponíveis para download nesta plataforma.</p><p>É recomendado que se faça uma cópia offline dos arquivos, para uso em caso de problemas de conexão.</p>'}
                classStyle={'left_section'}
            />
            <Right
                top={true}
                logoHeight={'34px'}
                borderRadius={'34px'}
                classStyle={'right_section'}
            >
                <div className={d.containers}>
                    <div className={d.inner}>
                        <div className={d.downloads}>
                            {sections?.map((section: any, i: number) => {
                                if (downloads[section.name].length > 0) {
                                    return (
                                        <div className={d.folder_download} key={i}>
                                            <div className={d.folder_download_title}>
                                                <h2>{section.name}</h2>
                                            </div>
                                            <div className={d.folder_download_content}>
                                                {downloads[section.name].map((download: any, j: number) => (
                                                    <Link to={download.link} key={j} target="_blank">
                                                        <div className={d.download_item}>
                                                            <div className={d.icon_type_download}>
                                                                <ICON_Downloads color={settings?.colorHightlight} />
                                                            </div>
                                                            <p className={d.name_download}>{download.name}</p>
                                                            {/*Data que o arquivo foi upado 
                                                            
                                                            <p className={d.date_download}>
                                                                {`${new Date(1691696953518).toLocaleDateString()} -
                                ${new Date(1691696953518).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                                                            </p> */}
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                }
                                return null;  // return null if no downloads for this section
                            })}

                        </div>
                    </div>
                </div>
            </Right>
        </>
    )
}