import { QUERY_FILE } from "../../lib/Queries"

export const Image = ({ id }: { id: string }) => {

    const image = QUERY_FILE(id)

    return (
        <img
            src={image?.file?.url}
            alt={image?.file?.name}
            width={image?.file?.width}
            height={image?.file?.height}
        />
    )
}
