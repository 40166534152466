import Types from '../../lib/Types/types';
import Blocks from './blocks';

interface BlockProps {
    name: string;
    attributes: any;
    content: any;
    className: string;
    style: any;
}

export const Block: React.FC<BlockProps> = ({ name, attributes, content, className, style }) => {
    const ToolComponent = Types[name];
    return (
        <>
            {typeof content === "string" ? (
                <ToolComponent
                    name={name}
                    attributes={attributes}
                    content={content}
                    className={className}
                    style={style}
                />
            ) : (
                <ToolComponent
                    name={name}
                    attributes={attributes}
                    className={className}
                    style={style}
                >
                    <Blocks blocks={content} />
                </ToolComponent>
            )}
        </>
    );
};
