import React from "react";

import'./index.scss';
import { TypeBlock } from "../../../types/blocks";

const SVG = ({ attributes, content, className, style }: TypeBlock) => {

  const { iframe } = attributes;

  return React.createElement(
    "div",
    {
      className: className,
      style: style,
      dangerouslySetInnerHTML: { __html: iframe },
    },
  );
};

export default SVG;
