import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//hocs
import { useAuth } from "../../hoc/UseAuth";
//utils
import { useScrollPosition } from '../../utils/scroll'
import { SMenu } from '../../utils/svgs'
//datas
import brand from "../../data/brand.json";
//parts
import { MenuItem } from './Item'

import m from "./menu.module.scss";
import { QUERY_BRANDSLUG } from '../../lib/Queries';

export const Menu = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openItem, setOpenItem] = useState<null | string>(null);
    const scrollY = useScrollPosition();
    const auth = useAuth();
    const menuRef = useRef(null); // Ref para o elemento m.bu_menu

    const sections = QUERY_BRANDSLUG()?.sections;
    

    // Função para fechar o menu quando clicar fora
    const handleClickOutside = (e: any) => {
        if (!e.target.closest(`.${m.bu_menu}`)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        // Adiciona um event listener para detectar cliques fora do menu
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Remove o event listener ao desmontar o componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOpenItem = (slug: string) => {
        if (openItem === slug) {
            setOpenItem(null);  // se o mesmo item for clicado, ele será fechado
        } else {
            setOpenItem(slug);  // caso contrário, o submenu do novo item será aberto
        }
    }

    const handleLogout = async () => {
        try {
            await auth.logout();
            console.log("Logout bem-sucedido");
            navigate("/signin");
            // Aqui você pode realizar outras ações após o logout bem-sucedido, se necessário
        } catch (error) {
            console.error("Erro ao fazer logout:", error);
        }
    }

    return (
      <div
        className={`${open ? m.active : ""} ${m.bu_menu}`}
        style={{
          maxHeight: scrollY >= 110 ? "100vh" : "calc(100vh - 110px)",
          marginTop: scrollY >= 110 ? 0 : 110,
        }}
        ref={menuRef}
      >
        <div className={m.top_menu}>
          <div className={m.action_menu}>
            <div
              className={m.icon_menu}
              onClick={() => setOpen(!open)}
            >
              <SMenu
                color={"#000000"}
                active={open}
              />
            </div>
          </div>
          <div className={m.items}>
            <MenuItem
              name={"Home"}
              link={"/"}
            />
            {sections?.map((s: any, i: number) => {
              return (
                <MenuItem
                  key={i}
                  name={s.name}
                  slug={s.slug}
                  link={`/s/${s.slug}/${s.modules && s.modules[0]?.id}`}
                  sub_section={s.subsections}
                  modules={s.modules}
                  openItem={openItem === s.slug}
                  handleOpenItem={handleOpenItem}
                />
              );
            })}
            <MenuItem
              name={"Downloads"}
              link={"/downloads"}
            />
            <MenuItem
              name={"Logout"}
              handleOpenItem={handleLogout}
            />
          </div>
        </div>
      </div>
    );
}
