import { Left } from '../../components/Structure/Left'
import { Right } from '../../components/Structure/Right'
import { SLogo } from '../../utils/svgs'
import { Link } from 'react-router-dom'
import { Image } from '../../components/Image';
import { QUERY_BRANDSLUG } from '../../lib/Queries'
import u from "./universe.module.scss";

export const Universe = () => {
    const brand = QUERY_BRANDSLUG();

    return (
        <>
            <Left
                urlLogoTop={'/'}
                logoTop={<SLogo color={brand?.settings.colorHightlight} />}
                logoHeight={'34px'}
                title={'Universe'}
                inside={`<p>Olá Nome, este é o universo da marca <b>${brand?.name}</b>.</p><p>Aqui você terá acesso a todos produtos da marca.</p><p>Como perfil de administrador, poderá editar as permissões de acesso e os conteúdos de cada produto.</p>`}
                classStyle={'left_home'}
            />
            <Right
                background={brand?.settings.colorSecondaryLogin}
                classStyle={'right_home'}
            >
                <div className="containers">
                    <div className='items'>
                        {brand?.highlights.map((s: any, i: number) => {
                            return (
                                <div className='item'>
                                    <Link to={`/s/${s.slug}/${s.modules[0]?.id}`}>
                                        <Image id={s.cover.id} />
                                        <p className='nome'>{s.name} </p>
                                    </Link>
                                </div>
                            )
                        })}
                        <div className='item'>
                            <Link to={`/downloads`}>
                                <img src={brand?.settings?.downloadImage?.url} alt="" />
                                <p className='nome'>Downloads </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Right>
        </>
    )
}
