const types = [
  "box",
  "box_children",
  "columns",
  "column",
  "heading",
  "paragraph",
  "image",
  "svg",
  "embedded",
];

const Types: any = {};

types.forEach((type) => {
  Types[type] = require(`./${type}`).default;
});

export default Types;
