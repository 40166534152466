import { Link, useParams } from "react-router-dom";

import { TypeBlock } from "../../../types/blocks";
import { QUERY_BRANDSLUG, QUERY_SECTION } from "../../Queries";

import "./styles.scss";
import { useEffect, useState } from "react";

const BoxChildren = ({ id, name, attributes, className, children, style }: TypeBlock) => {
    const { download, columns, rows, textStyle } = attributes;

    const { section } = useParams();
    const sec = QUERY_BRANDSLUG()?.sections?.find((s: any) => s.slug === section);

    const [textColors, setTextColors] = useState([]);

    useEffect(() => {
        const fetchTextColors = async () => {
            if (sec?.subsections) {
                const colorsPromises = sec.subsections.map(async (s: any) => {
                    const textColor = await getContrastColor(s.cover?.url, 100);
                    return textColor;
                });

                const downloadTextColor = await getContrastColor(download?.url, 100);
                colorsPromises.push(downloadTextColor);

                const resolvedColors: any = await Promise.all(colorsPromises);
                setTextColors(resolvedColors);
            }
        };

        fetchTextColors();
    }, [sec]);

    const calculateLuminance = (r: any, g: any, b: any) => {
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    const calculateContrast = (color1: any, color2: any) => {
        const luminance1 = calculateLuminance(color1[0], color1[1], color1[2]);
        const luminance2 = calculateLuminance(color2[0], color2[1], color2[2]);
        const lighter = Math.max(luminance1, luminance2);
        const darker = Math.min(luminance1, luminance2);
        return (lighter + 0.05) / (darker + 0.05);
    };

    const getPixelColor = (imageData: any, x: any, y: any) => {
        const index = (y * imageData.width + x) * 4;
        const red = imageData.data[index];
        const green = imageData.data[index + 1];
        const blue = imageData.data[index + 2];
        return [red, green, blue];
    };

    const getContrastColor = async (
        img: HTMLImageElement | string,
        bottomHeight: number
    ) => {

        console.log(img);

        if (typeof img === "string") {
            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.src = img;

            return new Promise<string>((resolve, reject) => {
                image.onload = function () {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = image.width;
                    canvas.height = image.height;

                    ctx?.drawImage(
                        image,
                        0,
                        image.height - bottomHeight,
                        image.width,
                        bottomHeight,
                        0,
                        0,
                        image.width,
                        bottomHeight
                    );
                    const imageData = ctx?.getImageData(0, 0, image.width, bottomHeight);

                    let totalR = 0,
                        totalG = 0,
                        totalB = 0;
                    for (let x = 0; x < image.width; x++) {
                        for (let y = 0; y < bottomHeight; y++) {
                            const color = getPixelColor(imageData, x, y);
                            totalR += color[0];
                            totalG += color[1];
                            totalB += color[2];
                        }
                    }
                    const avgR = totalR / (image.width * bottomHeight);
                    const avgG = totalG / (image.width * bottomHeight);
                    const avgB = totalB / (image.width * bottomHeight);
                    const bgColor = [avgR, avgG, avgB];

                    const contrastRatio = calculateContrast([255, 255, 255], bgColor);
                    const calculatedTextColor = contrastRatio >= 4.5 ? "#fff" : "#000";

                    resolve(calculatedTextColor);
                };

                image.onerror = function (error) {
                    reject(error); // Rejeita a promessa se ocorrer um erro ao carregar a imagem
                };
            });
        } else {
            return ''; // Retorna uma string vazia se a imagem for inválida
        }
    };

    return (
        <div className={className}
            style={{
                ...style,
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridAutoRows: `minmax(calc(100% / ${rows}), auto)`
            }}
        >
            {sec?.subsections?.map((s: any, i: number) => {
                const textColor = textColors[i] || '#fff';

                return (
                    <Link key={i} to={`/sb/${section}/${s.slug}/${s.modules[0]?.id}`}>
                        <img src={s?.cover?.url} alt={s?.cover?.alt} />
                        <p className="name" style={{ ...textStyle, color: textColor }}>{s.name}</p>
                    </Link>
                );
            })}

            <Link to="/downloads">
                <img src={download.url} alt="Adicionar" />
                <p className='name' style={{ ...textStyle, color: textColors ? textColors[textColors.length - 1] : '#fff' }}>Downloads </p>
            </Link>
        </div>
    );
};

export default BoxChildren;
