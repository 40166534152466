import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Left } from '../components/Structure/Left';
import { Right } from '../components/Structure/Right';
import { SLogo } from '../utils/svgs';
import { QUERY_BRANDSLUG } from '../lib/Queries';
import "./home.scss";
import { modules } from '../utils/linkModules';

export const Home = () => {
    const brand = QUERY_BRANDSLUG();
    const allModules = modules()
    const [textColors, setTextColors] = useState<string[]>([]);

    useEffect(() => {
        const fetchTextColors = async () => {
            if (brand?.highlights) {
                const colorsPromises = brand.highlights.map(async (s: any) => {
                    const textColor = await getContrastColor(s.cover?.url, 100);
                    return textColor;
                });

                const downloadTextColor = await getContrastColor(brand?.settings?.downloadImage?.url, 100);
                colorsPromises.push(downloadTextColor);

                const resolvedColors = await Promise.all(colorsPromises);
                setTextColors(resolvedColors);
            }
        };

        fetchTextColors();
    }, [brand]);



    const calculateLuminance = (r: any, g: any, b: any) => {
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    const calculateContrast = (color1: any, color2: any) => {
        const luminance1 = calculateLuminance(color1[0], color1[1], color1[2]);
        const luminance2 = calculateLuminance(color2[0], color2[1], color2[2]);
        const lighter = Math.max(luminance1, luminance2);
        const darker = Math.min(luminance1, luminance2);
        return (lighter + 0.05) / (darker + 0.05);
    };

    const getPixelColor = (imageData: any, x: any, y: any) => {
        const index = (y * imageData.width + x) * 4;
        const red = imageData.data[index];
        const green = imageData.data[index + 1];
        const blue = imageData.data[index + 2];
        return [red, green, blue];
    };

    const getContrastColor = async (
        img: HTMLImageElement | string,
        bottomHeight: number
    ) => {
        if (typeof img === "string") {
            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.src = img;

            return new Promise<string>((resolve, reject) => {
                image.onload = function () {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = image.width;
                    canvas.height = image.height;

                    ctx?.drawImage(
                        image,
                        0,
                        image.height - bottomHeight,
                        image.width,
                        bottomHeight,
                        0,
                        0,
                        image.width,
                        bottomHeight
                    );
                    const imageData = ctx?.getImageData(0, 0, image.width, bottomHeight);

                    let totalR = 0,
                        totalG = 0,
                        totalB = 0;
                    for (let x = 0; x < image.width; x++) {
                        for (let y = 0; y < bottomHeight; y++) {
                            const color = getPixelColor(imageData, x, y);
                            totalR += color[0];
                            totalG += color[1];
                            totalB += color[2];
                        }
                    }
                    const avgR = totalR / (image.width * bottomHeight);
                    const avgG = totalG / (image.width * bottomHeight);
                    const avgB = totalB / (image.width * bottomHeight);
                    const bgColor = [avgR, avgG, avgB];

                    const contrastRatio = calculateContrast([255, 255, 255], bgColor);
                    const calculatedTextColor = contrastRatio >= 4.5 ? "#fff" : "#000";

                    resolve(calculatedTextColor);
                };

                image.onerror = function (error) {
                    reject(error); // Rejeita a promessa se ocorrer um erro ao carregar a imagem
                };
            });
        } else {
            return ''; // Retorna uma string vazia se a imagem for inválida
        }
    };

    const link = (section: string) => {
        if (allModules) {
            const s = Object.values(allModules).find((s: any) => s.section === section || s.subsection === section);
            return s;
        }
        return null;
    };

    return (
        <>
            <Left
                urlLogoTop={'/'}
                settings={brand?.settings}
                logoTop={<SLogo color={brand?.settings?.colorHightlight} />}
                logoHeight={'34px'}
                title={'Nossa identidade é a maior expressão da nossa marca.'}
                inside={`<p>Esse é o Universo da Marca® de <b>${brand?.name}</b>. Aqui, você encontra tudo que você precisa saber sobre nossa marca. Utilize-o como um guia para criar uma comunicação forte e consistente, sempre levando em consideração as orientações aqui presentes.
Em caso de dúvidas, entre em contato com o departamento responsável.</p>`}
                classStyle={'left_home'}
            />
            <Right
                background={brand?.settings.colorSecondaryLogin}
                classStyle={'right_home'}
            >
                <div className="containers">
                    <div className='items'>
                        {brand?.highlights.map((s: any, i: number) => {
                            const textColor = textColors[i] || '#fff';
                            const url: any = link(s.slug);

                            return (
                                <div className='item' key={i}>
                                    <Link to={url.subsection ? `/sb/${url.section}/${url.subsection}/${url.id}` : `/s/${url.section}/${url.id}`}>
                                        <img src={s.cover?.url} alt={s.cover?.alt} />
                                        <p style={{ color: textColor }} className='nome'>{s.name}</p>
                                    </Link>
                                </div>
                            );
                        })}

                        <div className='item'>
                            <Link to={`/downloads`}>
                                <img src={brand?.settings?.downloadImage?.url} alt="" />
                                <p style={{ color: textColors ? textColors[textColors.length - 1] : '#fff' }} className='nome'>Downloads </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Right>
        </>
    )
}
