import Types from "../../lib/Types/types";
import './block.scss';
import { Block } from "./block";

interface TypeBlock {
    id: string;
    name: string;
    attributes: any;
    content: any;
    className: string;
    style: any;
    allowTypes: string[];
}

const Blocks = ({ blocks }: { blocks?: any }) => {

    return blocks?.map((tool: TypeBlock, i: number) => {
        const { id, name, attributes, content, className, style } = tool;
        const ToolComponent = Types[name];

        return ToolComponent && (
            <Block
                key={`${name}_${i}`}
                name={name}
                attributes={attributes}
                content={content}
                className={className}
                style={style}
            />
        )
    });

};

export default Blocks;
