import { BrowserRouter, Routes, Route } from 'react-router-dom';
//hocs
import { AuthProvider } from './AuthContext';
import { AuthRoute } from './UseAuth';
//pages
import { Signin } from '../pages/signin';
import { Home } from '../pages';
import { Modules } from '../pages/modules';
import { Downloads } from '../pages/downloads';
import { Chat } from '../pages/chat';
import { Universe } from '../pages/universe';

export default function AppRoute() {

    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AuthRoute Component={Home} restrict={true} />} />
                    <Route path="/universe" element={<AuthRoute Component={Universe} restrict={true} />} />
                    <Route path="/s/:section/:module" element={<AuthRoute Component={Modules} restrict={true} />} />
                    <Route path="/sb/:section/:subsection/:module" element={<AuthRoute Component={Modules} restrict={true} />} />
                    <Route path="/downloads" element={<AuthRoute Component={Downloads} restrict={true} />} />
                    <Route path="/signin" element={<AuthRoute Component={Signin} restrict={false} />} />
                    <Route path="/signup" element={<Signin />} />
                    <Route path="/chat" element={<Chat />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}
