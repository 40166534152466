// MenuItem.tsx

import { Link } from 'react-router-dom';
import { ArrowDownSLine } from '../../utils/svgs';
import { MenuSubItem } from './SubItem';
import { MenuItemProps } from './types'; // Importe a interface

import m from "./menu.module.scss";

export const MenuItem = ({ name, slug, link, sub_section, openItem, handleOpenItem }: MenuItemProps) => {
    return (
        <div className={m.item}>
            <div className={m.item_name} onClick={() => handleOpenItem && handleOpenItem(slug as string)}>
                {link ? (
                    <Link to={link}>
                        <p>{name}</p>
                    </Link>
                ) : (
                    <p>{name}</p>
                )}
                <div className={m.item_name_arrow}>
                    {sub_section && sub_section?.length != 0 && <ArrowDownSLine color={"#000"} />}
                </div>
            </div>
            {sub_section && <MenuSubItem
                open={openItem}
                sections={sub_section}
                parent={slug}
            />}
        </div>
    )
}
