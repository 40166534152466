import { QUERY_BRANDSLUG } from "../lib/Queries";

export const modules = () => {
  const sections = QUERY_BRANDSLUG()?.sections;
  const modules: any = {};

  sections?.forEach((s: any) => {
    s.modules?.forEach((m: any, i: number) => {
      modules[m.id] = {
        section: s.slug,
        ...m,
        name: i === 0 ? s.name : m.name,
      };

      s.subsections?.forEach((sb: any) => {
        sb.modules?.forEach((mb: any, ib: number) => {
          modules[mb.id] = {
            section: s.slug,
            subsection: sb.slug,
            ...mb,
            name: ib === 0 ? sb.name : mb.name,
          };
        });
      });
    });
  });
  return modules;
};
